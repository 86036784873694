import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, TextBlock } from '@lxlabs/change-ad-components';
import Graphic from './graphic';

const Style = styled(Card)`
  ${({ fullWidth }) => (fullWidth) && `
    @media not screen and (max-width: 834px) {
      grid-column: 1 / 4;
    }
  `}
`;

const Content = styled(TextBlock)`
  max-width: 610px;
`;

const FabricCard = ({ children, graphic, fullWidth }) => (
  <Style fullWidth={fullWidth}>
    <Graphic size="medium" name={graphic} />
    <Content alignment="center" spacing={{ top: 'small' }}>
      {children}
    </Content>
    <span />
  </Style>
);

FabricCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  graphic: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

FabricCard.defaultProps = {
  fullWidth: false,
};

export default FabricCard;
